import React, { useState, useEffect, useRef } from "react";
import localData from "../../localData";
import { Button } from "../";

export default function Select({
    label,
    items = [],
    setItems = () => console.log('Error: "setItems function required"'),
    placeholder = "select",
    className = "",
    variant,
    color,
    toggleIcon,
    children,
    size = "md",
    isLoading,
    // listStyle = "",
    // listStyleColor = "",
    callback = ()=>{},
    disabled=false
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [activeOption, setActiveOption] = useState(-1);

    const clickWrapper = useRef(null);
    const selectRef = useRef(null);
    const activeOptionRef = useRef(null);

    const { arrowDown } = localData.svgs;
    const { preloader } = localData.images;

    useEffect(() => {
        let handler = (e) => !clickWrapper.current.contains(e.target) && setIsOpen(false);
        document.addEventListener("click", handler);
        return () => document.removeEventListener("click", handler);
    });

    const openMenu = () => {
        setIsOpen(true);

        setTimeout(() => scrollIntoView(), 100);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const setActive = (id) => {
        let tempItems = [...items];
        tempItems = tempItems.map((item) => ({
            ...item,
            isActive: item.id === id,
        }));
        setItems(tempItems);

        setActiveOption(tempItems.findIndex((item) => item.Active === true));
        callback(tempItems.find((item) => item.id === id))
    };

    const scrollIntoView = () => {
        // const activeItem = activeOptionRef.current;
        // activeItem?.scrollIntoView({
        //     // behavior: 'smooth',
        //     block: "nearest",
        //     inline: "nearest",
        // });
    };

    useEffect(()=>{
        setActiveOption(items.findIndex((item) => item.isActive === true));
       //setActiveOption(1);
    },[items])

    const handleKeyDown = (e) => {
        if (["ArrowUp", "ArrowDown"].includes(e.key)) e.preventDefault(); // to prevent autocompleteField default behaviour when pressed ArrowUp or ArrowDown
        if (e.key === "Tab") closeMenu();
        if (["ArrowUp", "ArrowDown"].includes(e.key) && !isOpen) {
            openMenu();
            return;
        }
        switch (e.key) {
            case "ArrowDown":
                if (activeOption >= items.length - 1) return;
                setActiveOption((prevIndex) => prevIndex + 1);
                break;
            case "ArrowUp":
                if (activeOption <= 0) return;
                setActiveOption((prevIndex) => prevIndex - 1);
                break;
            case "Enter":
                activeOptionRef.current?.click();
                isOpen && setTimeout(() => closeMenu(), 0);
                break;
        }
    };

    useEffect(() => {
        scrollIntoView();
    }, [activeOption]);

    const getPlaceholder = () => {
        return (
            <div className="wrapper placeholder">
                <span className="select-toggle-title">{placeholder}</span>
            </div>
        );
    };

    const getActiveItem = () => {
        const activeItem = items.find((item) => item.isActive);

        if (!activeItem) return getPlaceholder();

        const { title, startIcon, endIcon, listStyleColor } = activeItem;

        return (
            <div className="wrapper">
                {listStyleColor && <span className={`list-style list-style-${listStyleColor}`}></span>}
                {startIcon && <span className="startIcon">{startIcon}</span>}
                <span className={`select-toggle-title`}>{title}</span>
                {endIcon && <span className="endIcon">{endIcon}</span>}
            </div>
        );
    };

    // don't render menu while it closed (replace "isOpen" variable with "isAnimate" from select classname and remove commented isOpen scopes around menu)
    // const [isAnimate, setIsAnimate] = useState(false);

    // useEffect(() => {
    //     setIsAnimate(isOpen);
    // }, [isOpen]);
    //

    return (
        <div
            className={`select select-${size} ${isOpen ? "active" : ""}  ${className} ${
                isLoading ? "isLoading" : ""
            } ${disabled ? 'disabled': ''}`}
            ref={selectRef}
        >
            {label && <label className="select-label">{label}</label>}
            <div className="select-toggle-wrapper" ref={clickWrapper}>
                <Button
                    className={`select-toggle`}
                    data-toggle="select"
                    onClick={() => (isOpen ? closeMenu() : openMenu())}
                    variant={variant}
                    color={color}
                    onKeyDown={handleKeyDown} 
                >
                    {!items.length ? getPlaceholder() : getActiveItem()}
                    <span className="endIcon select-toggle-icon">
                        {(isLoading && <img src={preloader} />) || toggleIcon || arrowDown}
                    </span>
                </Button>
            </div>

            {/* {isOpen && ( // don't render menu while it closed */}
            <div className="select-menu">
                {!items.length ? (
                    <div className="select-item disabled">empty.</div>
                ) : (
                    items.map( 
                        ({ title, startIcon, endIcon, id, listStyleColor, propertyIndex = "" }, index) => (
                            <div 
                                key={index}
                                id={`select-item-${id}`}
                                className={`select-item ${activeOption === index ? "active" : ""}`}
                                onClick={() => setActive(id)}
                                ref={activeOption === index ? activeOptionRef : null}
                            >
                                {listStyleColor && (
                                    <span className={`list-style list-style-${listStyleColor}`}></span>
                                )}
                                {startIcon && <span className="startIcon">{startIcon}</span>}
                                <span className={`select-item-title`} title={title}>
                                    {propertyIndex && (
                                        <span className="select-item-title-prefix text-primary">
                                            {propertyIndex}
                                        </span>
                                    )}{" "}
                                    {title}
                                </span>
                                {endIcon && <span className="endIcon">{endIcon}</span>}
                            </div>
                        )
                    )
                )}
                {children}
            </div>
            {/* )} */}
        </div>
    );
}






// import React, { useState, useEffect, useRef } from "react";
// import localData from "../../localData";
// import { Button } from "../";
// import { Tooltip } from "react-tooltip";
// import ReactDOMServer from "react-dom/server";

// export default function Select({
//     name = '',
//     label,
//     labelInfo,
//     items = [],
//     setItems = () => console.log('Error: "setItems function required"'),
//     placeholder = "select",
//     className = "",
//     variant = "outlined",
//     color,
//     toggleIcon,
//     children,
//     size = "md",
//     isLoading,
//     callback = () => {},
//     secondCallback = ()=>{},
//     // listStyle = "",
//     // listStyleColor = "",
// }) {
//     const [isOpen, setIsOpen] = useState(false);
//     const [activeOption, setActiveOption] = useState(-1);

//     const clickWrapper = useRef(null);
//     const selectRef = useRef(null);
//     const activeOptionRef = useRef(null);

//     const { arrowDown } = localData.svgs;
//     const { preloader } = localData.images;

//     useEffect(() => {
//         let handler = (e) => !clickWrapper.current.contains(e.target) && setIsOpen(false);
//         document.addEventListener("click", handler);
//         return () => document.removeEventListener("click", handler);
//     });

//     const openMenu = () => {
//         setIsOpen(true);

//         setTimeout(() => scrollIntoView(), 100);
//     };

//     const closeMenu = () => {
//         setIsOpen(false);
//     };

//     const setActive = (id) => {
//         let tempItems = [...items];
//         tempItems = tempItems.map((item) => ({
//             ...item,
//             isSelected: item.id === id,
//         }));
//         setItems(tempItems);
//         setActiveOption(tempItems.findIndex((item) => item.isSelected === true));
//         callback(tempItems.find((item) => item.id === id))
//         secondCallback(tempItems,name)
//     };

//     const scrollIntoView = () => {
//         // const activeItem = activeOptionRef.current;
//         // activeItem?.scrollIntoView({
//         //     // behavior: 'smooth',
//         //     block: "nearest",
//         //     inline: "nearest",
//         // });
//     };

//     useEffect(()=>{
//         setActiveOption(items.findIndex((item) => item.isSelected === true));
//     },[items])

//     const handleKeyDown = (e) => {
//         if (["ArrowUp", "ArrowDown"].includes(e.key)) e.preventDefault(); // to prevent autocompleteField default behaviour when pressed ArrowUp or ArrowDown
//         if (e.key === "Tab") closeMenu();
//         if (["ArrowUp", "ArrowDown"].includes(e.key) && !isOpen) {
//             openMenu();
//             return;
//         }
//         switch (e.key) {
//             case "ArrowDown":
//                 if (activeOption >= items.length - 1) return;
//                 setActiveOption((prevIndex) => prevIndex + 1);
//                 break;
//             case "ArrowUp":
//                 if (activeOption <= 0) return;
//                 setActiveOption((prevIndex) => prevIndex - 1);
//                 break;
//             case "Enter":
//                 activeOptionRef.current?.click();
//                 isOpen && setTimeout(() => closeMenu(), 0);
//                 break;
//         }
//     };

//     useEffect(() => {
//         scrollIntoView();
//     }, [activeOption]);

    

//     const getPlaceholder = () => {
//         return (
//             <div className="wrapper placeholder">
//                 <span className="select-toggle-title">{placeholder}</span>
//             </div>
//         );
//     };

//     const getActiveItem = () => {
//         const activeItem = items.find((item) => item.isSelected);

//         if (!activeItem) return getPlaceholder();

//         const { title, startIcon, endIcon, listStyleColor,color } = activeItem;

//         return (
//             <div className="wrapper">
//                 {listStyleColor && <span className={`list-style list-style-${listStyleColor}`}></span>}
//                 {startIcon && <span className="startIcon">{startIcon}</span>}
//                 <span className={`select-toggle-title ${color ? 'text-' + color : '' }`} >{title}</span>
//                 {endIcon && <span className="endIcon">{endIcon}</span>}
//             </div>
//         );
//     };

//     // don't render menu while it closed (replace "isOpen" variable with "isAnimate" from select classname and remove commented isOpen scopes around menu)
//     // const [isAnimate, setIsAnimate] = useState(false);

//     // useEffect(() => {
//     //     setIsAnimate(isOpen);
//     // }, [isOpen]);
//     //

//     return (
//         <div
//             className={`select select-${size} ${isOpen ? "active" : ""}  ${className} ${
//                 isLoading ? "isLoading" : ""
//             }`}
//             ref={selectRef}
//         >
//             {label && (
//                 <label
//                     className="select-label"
//                     data-tooltip-id={`tooltip-campaign-${name}`}
//                     data-tooltip-content={labelInfo}
//                 >
//                     {label}
//                 </label>
//             )}
//             {labelInfo && <Tooltip id={`tooltip-campaign-${name}`} className="custom-tooltip" />}
//             <div className="select-toggle-wrapper" ref={clickWrapper}>
//                 <Button
//                     className={`select-toggle`}
//                     data-toggle="select"
//                     onClick={() => (isOpen ? closeMenu() : openMenu())}
//                     variant={variant}
//                     color={color}
//                     onKeyDown={handleKeyDown}
//                 >
//                     {!items.length ? getPlaceholder() : getActiveItem()}
//                     <span className="endIcon select-toggle-icon">
//                         {(isLoading && <img src={preloader} />) || toggleIcon || arrowDown}
//                     </span>
//                 </Button>
//             </div>

//             {/* {isOpen && ( // don't render menu while it closed */}
//             <div className="select-menu">
//                 {!items.length ? (
//                     <div className="select-item disabled">empty.</div>
//                 ) : (
//                     items.map(
//                         ({ title, startIcon, endIcon, id, listStyleColor, propertyIndex = "", color }, index) => (
//                             <div
//                                 key={index}
//                                 id={`select-item-${id}`}
//                                 className={`select-item ${activeOption === index ? "active" : ""}`}
//                                 onClick={() => setActive(id)}
//                                 ref={activeOption === index ? activeOptionRef : null}
//                             >
//                                 {listStyleColor && (
//                                     <span className={`list-style list-style-${listStyleColor}`}></span>
//                                 )}
//                                 {startIcon && <span className="startIcon">{startIcon}</span>}
//                                 <span className={`select-item-title ${color ? 'text-' + color : '' }`} title={title}  >
//                                     {propertyIndex && (
//                                         <span className="select-item-title-prefix text-primary">
//                                             {propertyIndex}
//                                         </span>
//                                     )}{" "}
//                                     {title}
//                                 </span>
//                                 {endIcon && <span className="endIcon">{endIcon}</span>}
//                             </div>
//                         )
//                     )
//                 )}
//                 {children}
//             </div>
//             {/* )} */}
//         </div>
//     );
// }
