import React, { useEffect, useState } from "react";
import { Navbar, CampaignsWizard, Tabs } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import CampaignsGraph from "./campaigns-graph/CampaignsGraph.jsx";
import CampaignsTable from "./campaigns-table/CampaignsTable.jsx";
// import BlockchainsTable from "./blockchains-table/BlockchainsTable.jsx";
import Blockchains from "./blockchains/Blockchains.jsx";

export default function Campaigns() { 
    useEffect(() => {
        document.title = "Blockchain-Ads Platform"; 
        localStorage.removeItem("created_publisher_id");
        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content = "Empower change. Join our campaign today!";
    }, []);

    const { fade } = useGlobalContext().animations;
    const {
        requestedData,
        isLoading,
        getLoadBlockchainData,
        blockchainsState,
        activeCampaign,
        setIsLoading,
    } = useGlobalContext();
    const { blockchainTabs, setActiveNetwork } = blockchainsState;

    const { plus, arrowRightExtended, checkbox } = localData.svgs;

    return (
        <motion.main {...fade} className="main campaigns-page">
            <Navbar title="Earnings & stats" />

            <div className="row"> 
                <div className="col">
                    <h2 className="campaign-title display-2">all websites</h2>
                    <h4 className="description">Monitor metrics, check reports and review earnings.</h4>
                </div> 
                <CampaignsWizard />
            </div>

            <CampaignsGraph />
            <br />
            <br />
            <br />
            <br />
            <br />

            <CampaignsTable />

           
        </motion.main>
    );
}
