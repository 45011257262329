import React, { useEffect, useState } from 'react';
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { Select, CopyTextButton } from "../../../../components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { postAPIwithAuth } from '../../../../services/webService';

const { pixel } = localData.images;
const Invocationcode ={ code: "test"};
const InvocationDrawer = ({ isOpen, onClose, zoneId }) => {
    const { conversionOptions: { trackAnEventScriptTag }, setConversionOptions, trackingState } = useGlobalContext();
    const [items, setItems] = useState([{
        id: 1,
        title: 'Asynchronous Tag',
        isActive: true
    }, {
        id: 2,
        title: 'JavaScript Tag',
        isActive: false
    }, {
        id: 3,
        title: 'Iframe Tag',
        isActive: false
    }]);
    const fetchZoneData = async (type) => {
        trackAnEventScriptTag.isLoading = true;
        try {

            if (type == 3) {
                const responseData = await postAPIwithAuth(`publisher/zone-get.php`, { zoneid: zoneId });
               
                Invocationcode.code=`<iframe id='abe21c97' name='abe21c97' src='https://server.blockchain-ads.com/www/delivery/afr.php?zoneid=`+zoneId+`&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='`+responseData.width+`' height='`+responseData.height+`' allow='autoplay'>
    <a href='https://server.blockchain-ads.com/www/delivery/ck.php?n=ace97dd6&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
        <img src='https://server.blockchain-ads.com/www/delivery/avw.php?zoneid=`+zoneId+`&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ace97dd6' border='0' alt='' />
    </a>
</iframe>`;

setConversionOptions(prevState => ({
    ...prevState,
    trackAnEventScriptTag: {
        ...prevState.trackAnEventScriptTag,
        isLoading: false,
        value: Invocationcode.code
    }
}));            
} else if (type == 2) {
                
                const responseData = await postAPIwithAuth(`publisher/zone-invocation.php`, { zone_id: zoneId, type: type });
                setConversionOptions(prevState => ({
                    ...prevState,
                    trackAnEventScriptTag: {
                        ...prevState.trackAnEventScriptTag,
                        isLoading: false,
                        value: responseData
                    }
                }));

            } else if (type == 1) {
                Invocationcode.code=`<ins data-revive-zoneid="`+zoneId+`" data-revive-id="a55728a252ddb9304b3fd15712250317"></ins>
<script async src="https://server.blockchain-ads.com/www/delivery/asyncjs.php"></script>`;
                
                setConversionOptions(prevState => ({
                    ...prevState,
                    trackAnEventScriptTag: {
                        ...prevState.trackAnEventScriptTag,
                        isLoading: false,
                        value: Invocationcode.code
                    }
                }));  
            }

            
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isOpen) {

            fetchZoneData(2);
            const updatedItems = items.map(item =>
                item.id === 2 ? { ...item, isActive: true } : { ...item, isActive: false }
            );
            setItems(updatedItems);
        }
    }, [isOpen, zoneId]);

    if (!isOpen) return null;

    const handleOnSubmit = (e) => {
        e.preventDefault();
    };

    const handleSelectChange = (updatedItems, name) => {
        fetchZoneData(updatedItems.id);
    };

    return (
        <div>
                 <h2 className="display-2">Generate Invocation Codes</h2>
                <p className="text-style-1">
                    Set up invocation code with Blockchain-Ads to have ads display on your website
                    website.
                </p>
                {/* <div>step 1 ----------- step 2 ---------- step 3</div> */}

                <br />
                <div className="form-legend">
                    <form action="" onSubmit={handleOnSubmit}>
                        <Select
                            items={items}
                            setItems={setItems}
                            placeholder="Select Invocation Type"
                            isLoading={false}
                            variant="outlined"
                            color="light"
                            className="organization-select"
                            callback={(items) => handleSelectChange(items, "zone")}
                        />
                    </form>
                </div>
                <div className="step-1">
                    <br />
                    <p className="text-style-1">
                        Copy and paste this code on your website where you want the ad to appear.
                    </p>

                    <div className="code">
                        <div className="row">
                            <div className="label">
                                <img src={pixel} alt="" />
                                code
                            </div>
                            <CopyTextButton
                                text={trackAnEventScriptTag.value}
                                disabled={!trackAnEventScriptTag.value}
                                size="medium"
                            />
                        </div>

                        <div className="script-wrapper" style={{ fontSize: "12px" }}>
                            {trackAnEventScriptTag.isLoading ? (
                                "Loading..."
                            ) : trackAnEventScriptTag.value ? (
                                <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                                    {trackAnEventScriptTag.value}
                                </SyntaxHighlighter>
                            ) : (
                                "Select a zone from the dropdown above to generate the invocation code"
                            )}
                        </div>
                    </div>
                </div>
        
        </div>
    );
};

export default InvocationDrawer;