import React from 'react';
import './ConfirmationModal.css';
export default function ConfirmationDialog({ isOpen, onClose, onConfirm, message }) {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
    <div className="confirmation-modal">
      <p>{message}</p>
      <div className="confirmation-modal-buttons">
        <button onClick={onConfirm} className="confirm-button">Confirm</button>
        <button onClick={onClose} className="cancel-button">Cancel</button>
      </div>
    </div>
  </div>
  );
}