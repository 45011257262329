
// import React, { PureComponent } from "react";
// import {
//     BarChart,
//     Bar,
//     LineChart,
//     Line,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     ResponsiveContainer,
// } from "recharts";
// import useFormatter from "../../../../hooks/useFormatter";

// const isSmallScreen = window.innerWidth < 768;
// let fontSize = isSmallScreen ? 8 : 12;
// let leftMargin = isSmallScreen ? -20 : -10;

// export default function Example({ data = [], defaultData = [], isLoading = false, lines = [] }) {
//     // static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";
//     const { formatNumber, formatCurrency } = useFormatter();
//     return (
//         <div className="position">
//             <div className="position-inner">
//                 <ResponsiveContainer width="100%" height="100%">
//                     <LineChart
//                         width={500}
//                         height={300}
//                         data={data.length && !isLoading ? data : defaultData}
//                         margin={{
//                             top: 30,
//                             right: 0,
//                             left: leftMargin,
//                             bottom: 30,
//                         }}
//                     >
//                         <CartesianGrid strokeDasharray="" vertical={false} />
//                         <XAxis
//                             dataKey="name"
//                             axisLine={false}
//                             tickLine={false}
//                             dy={15}
//                             tick={{ fontSize: fontSize }}
//                             // interval="auto"
//                         />
//                         <YAxis
//                             axisLine={false}
//                             tick={{ fontSize: fontSize }}
//                             tickLine={false}
//                             dx={-5}
//                             // ticks={[0, 500, 1000, 2500, 5000, 7500, 10000]}
//                         />
//                         <Tooltip
//                             formatter={(value, name, props) => {
//                                 if (name === "impressions") return formatNumber(value);
//                                 if (name === "ad spend") return formatCurrency(value, 2);
//                                 if (name === "clicks") return formatNumber(value);
//                                 if (name === "CPC") return formatCurrency(value, 2);
//                                 if (name === "CPM") return formatCurrency(value, 2);

//                                 // if (name === "Conversions") return formatNumber(value);
//                                 // if (name === "Engaged Sessions") return formatNumber(value);
//                                 // if (name === "Engagement Rate") return formatNumber(value);
//                                 // if (name === "Event Count") return formatNumber(value);
//                                 // if (name === "New Users") return formatNumber(value);
//                                 // if (name === "Total Revenue") return formatNumber(value);

                                
//                                 if (name === "unknown") return "0";
//                                 return formatNumber(value);
//                             }}
//                         />

//                         {!lines.length
//                             ? ""
//                             : lines.map((line, index) => {
//                                   return (
//                                       <Line
//                                           key={index}
//                                           dataKey={line.propertyName}
//                                           name={line.displayName}
//                                           stackId={data.length > 10 ? "a" : undefined}
//                                           type="linear"
//                                           strokeWidth="3"
//                                           //   fill={line.color}
//                                           stroke={line.color}
//                                           dot={false}
//                                       />
//                                   );
//                               })}
//                         <Line
//                             type="linear"
//                             dataKey="empty"
//                             name="unknown"
//                             stackId="a"
//                             strokeWidth="1"
//                             stroke={"#808080"}
//                             dot={false}
//                         />
//                     </LineChart>
//                 </ResponsiveContainer>
//             </div>
//         </div>
//     );
// }



import React, { PureComponent } from "react";
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? -20 : -10;

export default function Example({
    data = [],
    defaultData = [],
    isLoading = false,
    lines = [],
    className = "",
    isTooltipFormatted = false
}) {
    // static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";
    const { formatNumber, formatCurrency, addCommas } = useFormatter();

    // const CustomTooltip = ({ payload, label }) => {
    //     if (!payload || payload.length === 0) return null;

    //     // Retrieve the original values from the payload
    //     const originalData = payload[0].payload;
    //     const impressions = originalData.impressions || "";
    //     const amount_spent = originalData.amount_spent || "";
    //     const clicks = originalData.clicks || "";
    //     const CPM = originalData.CPM || "";
    //     const CPC = originalData.CPC || "";
    //     console.log(originalData, ' data')
    //     return (
    //         <div className="chart-tooltip">
    //             <strong className="label">{`${label}`}</strong>
    //             {impressions && <p className="label">{`Impressions: ${formatNumber(impressions)}`}</p>}
    //             {amount_spent && <p className="label">{`Ad Spend: ${formatCurrency(amount_spent, 2)}`}</p>}
    //             {clicks && <p className="label">{`Clicks: ${formatNumber(clicks)}`}</p>}

    //             {CPM && <p className="label">{`CPM: ${formatCurrency(CPM, 2)}`}</p>}
    //             {CPC && <p className="label">{`CPC: ${formatCurrency(CPC, 2)}`}</p>}
    //         </div>
    //     );
    // };
    return (
        <div className={`position ${className}`}>
            <div className="position-inner">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.length && !isLoading ? data : defaultData}
                        margin={{
                            top: 30,
                            right: 0,
                            left: leftMargin,
                            bottom: 30,
                        }}
                    >
                        <CartesianGrid strokeDasharray="" vertical={false} />
                        <XAxis
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            dy={15}
                            tick={{ fontSize: fontSize }}
                            // interval="auto"
                        />
                        <YAxis 
                            axisLine={false}
                            tick={{ fontSize: fontSize }}
                            tickLine={false}
                            tickFormatter={(tick) => addCommas(tick) || "0"}
                            dx={-5}
                            // ticks={[0, 500, 1000, 2500, 5000, 7500, 10000]}
                        />
                        <Tooltip
                            formatter={(value, name, props) => {
                                const originalValue = props.payload
                                if(isTooltipFormatted){
                                    if (name === "CPM") return formatCurrency(originalValue.AllCPM, 2);
                                    if (name === "CPC") return formatCurrency(originalValue.AllCPC, 2);
                                    // if (name === "CPM") return "-";
                                    // if (name === "CPC") return "-";
                                }
                                if (name === "impressions") return formatNumber(originalValue.impressions);
                                if (name === "ad spend") return formatCurrency(originalValue.amount_spent, 2);
                                if (name === "revenue") return formatCurrency(originalValue.revenue, 2);
                                if (name === "clicks") return formatNumber(originalValue.clicks);
                                if (name === "CPM") return formatCurrency(originalValue.CPM, 2);
                                if (name === "CPC") return formatCurrency(originalValue.CPC, 2);
                                
                                if (name === "unknown") return "0";
                                return formatNumber(value);
                            }}
                        />
                        {/* <Tooltip
                            formatter={(value, name, props) => {
                                // const find = lines.find(item => item.displayName == name)
                                // if(find && !find.isActive) return
                                console.log(props.payload)
                                const originalValue = props.payload
                                if (name === "impressions") return formatNumber(value);
                                if (name === "ad spend") return formatCurrency(value, 2);
                                if (name === "clicks") return formatNumber(value);
                                if (name === "CPC") return formatCurrency(value, 2);
                                if (name === "CPM") return formatCurrency(value, 2);

                                // if (name === "Conversions") return formatNumber(value);
                                // if (name === "Engaged Sessions") return formatNumber(value);
                                // if (name === "Engagement Rate") return formatNumber(value);
                                // if (name === "Event Count") return formatNumber(value);
                                // if (name === "New Users") return formatNumber(value);
                                // if (name === "Total Revenue") return formatNumber(value);

                                
                                if (name === "unknown") return "0";
                                return formatNumber(value);
                            }}
                        /> */}
                        {/* <Tooltip content={<CustomTooltip />} /> */}
                        {!lines.length
                            ? ""
                            : lines.map((line, index) => {
                                  if (!line.isActive) return;
                                  return (
                                      <Line
                                          key={index}
                                        //   dataKey={line.propertyName}
                                          dataKey={line.normalizeName}
                                          name={line.displayName}
                                          stackId={data.length > 10 ? "a" : undefined}
                                          type="linear"
                                          strokeWidth="3"
                                          //   fill={line.color}
                                          stroke={line.color}
                                          dot={false}
                                      />
                                  );
                              })}
                        <Line
                            type="linear"
                            dataKey="empty"
                            name="unknown"
                            stackId="a"
                            strokeWidth="1"
                            stroke={"#808080"}
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
