import React, { useEffect, useState } from "react";
import "./Drawer.css"; // Import the CSS file for styling
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { Button, Select } from "../../../../components";
import { postAPIwithAuth } from "../../../../services/webService";
const { pixel } = localData.images;
const FormDrawer = ({ isOpen, onClose, website, postFormData, className }) => {
    const { isDarkModeEnabled, successAlert } = useGlobalContext();
    const {
        conversionOptions: { trackAnEventScriptTag },
        setConversionOptions,
        trackingState,
    } = useGlobalContext();
    const initialFormData = {
        zonename: "",
        zonetype: "1",
        zonesize: "",
    };
    const [formData, setFormData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const drawerclose = document.querySelector(".drawer-backdrop");
    const refresh = document.querySelector(".refresh");
    const [errors, setErrors] = useState({});

    const createZone = async (formData) => {
        let newFormData = formData;
        newFormData.affiliateid = website;
        const splitParts = formData.zonesize.split("X").map((part) => part.trim());
        newFormData.delivery = formData.zonetype;
        newFormData.width = splitParts["0"];
        newFormData.height = splitParts["1"];
        trackAnEventScriptTag.isLoading = true;
        try {
            const responseData = await postAPIwithAuth(
                `publisher/${postFormData.hasOwnProperty("zoneid") ? "zone-update.php" : "zone-create.php"}`,
                newFormData
            );
            successAlert(
                `Zone ${postFormData.hasOwnProperty("zoneid") ? "updated" : "created"} successfully!`
            );
            drawerclose.click();
            refresh.click();
            setTimeout(() => {
                
                setFormData(initialFormData);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            let newFormData = postFormData;
            if (postFormData.hasOwnProperty("width")) {
                newFormData.zonesize = `${postFormData.width}X${postFormData.height}`;
                newFormData.zonename=postFormData.zonename
            } else {
                newFormData.zonesize = `${300}X${250}`;
                newFormData.zonename='My Zone'
            }
            setFormData(newFormData);
        }
    }, [isOpen]);

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!formData.zonename) {
            newErrors.zonename = "Zone Name is required";
            isValid = false;
        }

        if (!formData.zonesize) {
            newErrors.zonesize = "Zone Size is required";
        }
        setErrors(newErrors);
        return isValid;
    };

    useEffect(()=>{
        validateForm();
    },[formData])

    if (!isOpen) return null;

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (validateForm()) {
            createZone(formData);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const buttonText = (() => {
        if (postFormData.hasOwnProperty("zoneid") && postFormData.zoneid) {
            return "Edit";
        } else if (postFormData.isNew) {
            return "Add New";
        } else {
            return "Add New";
        }
    })();

    return (
        <React.Fragment>
            <h2 className={`campaigns-wizard-title display-2`}> {buttonText} Zone</h2>

            <div className={`tabs ${className}`} style={{padding: 0}}>
                <ul className="nav nav-tabs  ">
                    <li className="nav-item  ">
                        <button
                            data-tooltip-id="tooltip-btn-3ad745a8-dce9-4f8d-9942-a8774749ca67"
                            data-tooltip-html=""
                            title=""
                            className="btn btn-md btn-text-dark nav-link active   dark-mode-style"
                            type="submit"
                        >
                            Zone Information
                        </button>
                    </li>
                </ul>
                <div className="divider"></div>
                <div className="tab-content">
                    <div className="tab-pane fade show active">
                        <div className="campaign-information">
                            <div className="wrapper tab-content-center">
                                <form onSubmit={handleSubmit}>
                                    <div className="field ">
                                        <div className="wrapper">
                                            <label className="form-label">
                                                Zone Name *{" "}
                                                <small className="text-danger">
                                                    {isSubmitted && errors.zonename && (
                                                        <p>{errors.zonename}</p>
                                                    )}
                                                </small>
                                            </label>
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                name="zonename"
                                                id="zonename"
                                                className="form-control form-control-secondary  form-control-sm"
                                                placeholder="e.g. johnDoeCamp"
                                                defaultValue={formData.zonename}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="field ">
                                        <div className="wrapper">
                                            <label className="form-label">Zone Type *</label>
                                        </div>
                                        <div className="input-group">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    name="zonetype"
                                                    id="zonetype"
                                                    onChange={handleChange}
                                                    checked={true}
                                                    required
                                                />
                                                <span>Image Zone</span>
                                            </label>{" "}
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="10"
                                                    name="zonetype"
                                                    id="zonetype"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span>Video Zone</span>
                                            </label>
                                        </div>
                                        {isSubmitted && errors.zonetype && <p>{errors.zonetype}</p>}
                                    </div>
                                    <div className="field ">
                                        <div className="wrapper">
                                            <label className="form-label w-full">
                                                Zone Size *{" "}
                                                <small className="text-danger">
                                                    {errors.zonesize && <p>{errors.zonesize}</p>}
                                                </small>
                                            </label>
                                        </div>
                                        <div className="input-group">
                                            <select
                                                className="form-control form-control-secondary form-control-sm"
                                                value={formData.zonesize}
                                                name="zonesize"
                                                id="zonesize"
                                                aria-label="Default select example"
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Zone Size</option>
                                                <option value="300X250">
                                                    Medium Rectangle Banner (300 X 250)
                                                </option>
                                                <option value="320X100">
                                                    Large Mobile Banner (320 X 100)
                                                </option>
                                                <option value="728X90">Leader Board Banner (728 X 90)</option>
                                                <option value="900X600">
                                                    Leader Board Banner (900 X 600)
                                                </option>
                                                <option value="320X50">Smartphone Banner (320 X 50)</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row row-bottom btn-group">
                                        <Button
                                            size="sm"
                                            name="Save & Continue"
                                            variant="contained"
                                            color={className == 'edit-mode' ? 'warning': 'dark'}
                                            type="submit"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FormDrawer;
