import React, { useEffect, useState } from "react";
import {
    Field,
    Switch,
    Button,
    Select,
    MultiSelect,
    Autocomplete,
    MultiSelectSearch,
    CountriesAndBid,
    Modal,
    CopyTextButton,
} from "../../../components";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter";
import ReactDOMServer from "react-dom/server";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import TrackingHelp from "../../../pages/tracking/tracking-help/TrackingHelp";
import { postAPIwithAuth } from '../../../services/webService';

const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal,
    arrowRight,
    arrowLeft,
    penV2,
    textPreloader,
    BloomingImage,
    info,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;


export default function Targeting({ setActiveTab }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isParentModalOpen, setParentModalOpen] = useState(true);

    const closeParentModal = () => {
        setParentModalOpen(false);
    };

    const {
        requestedData,
        campaignState,
        setCampaignState,
        campaignWizardStatus,
        isDarkModeEnabled,
        campaignsState,
        activeTab,
        errorAlert,
        successAlert,
    } = useGlobalContext();
    const select = { id: "1" };

    const [invocation1, setInvocation1] = useState(null);
    const [zonename1, setZonename1] = useState(null);
    const [invocation2, setInvocation2] = useState(null);
    const [zonename2, setZonename2] = useState(null);
    const { ensureTokenValid,
        isTokenExpired,
        updateTokens,
        refreshAccessToken, } = useGlobalContext();
    const [items, setItems] = useState([{
        id: 1,
        title: 'Asynchronous Tag',
        isActive: true
    }, {
        id: 2,
        title: 'JavaScript Tag',
        isActive: false
    }, {
        id: 3,
        title: 'Iframe Tag',
        isActive: false
    }]);
    const handleOnSubmit = (e) => {
        e.preventDefault();
    };
    useEffect(() => {
        const updatedItems = items.map(item =>
            item.id === 2 ? { ...item, isActive: true } : { ...item, isActive: false }
        );
        fetchZoneData(updatedItems);
    }, []);
    const handleSelectChange = async (updatedItems) => {
        const user = localStorage.getItem("user");
        const parsedUser = JSON.parse(user);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-all.php?access_token=${parsedUser.access_token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    affiliateid: publisher_id,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if (result && result.result && result.result.length > 0) {
                fetchInvocationData1(result.result[0].zoneid, result.result[0].zonename, updatedItems);
            } else {
                console.warn('No zones found in the response');
            }

        } catch (error) {
            console.error('Fetch error:', error);
            if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                await updateTokens();
            } else {
                console.error('An unexpected error occurred:', error);
            }
        }
    };

    const fetchInvocationData1 = async (zone, name, type) => {
        if(!type) type = items.find(item=>item.isActive) //added
        const user = localStorage.getItem("user");
        const parsedUser = JSON.parse(user);

        try {
            let invocationCode = '';

            if (type.id === 3 || type.id == '') {
                // const responseData = await postAPIwithAuth('publisher/zone-get.php', { zoneid: zone });
                const responseData = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-get.php?access_token=${parsedUser.access_token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        zoneid: zone
                    }),
                });
                const zoneinformation = await responseData.json();
                invocationCode = `<iframe id='abe21c97' name='abe21c97' src='https://server.blockchain-ads.com/www/delivery/afr.php?zoneid=${zone}&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='${zoneinformation.width}' height='${zoneinformation.height}' allow='autoplay'>
    <a href='https://server.blockchain-ads.com/www/delivery/ck.php?n=ace97dd6&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
        <img src='https://server.blockchain-ads.com/www/delivery/avw.php?zoneid=${zone}&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ace97dd6' border='0' alt='' />
    </a>
</iframe>`;

            } else if (type.id === 2) {
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-invocation.php?access_token=${parsedUser.access_token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        zone_id: zone,
                        type: 2,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                invocationCode = await response.json();

            } else if (type.id === 1) {
                invocationCode = `<ins data-revive-zoneid="${zone}" data-revive-id="a55728a252ddb9304b3fd15712250317"></ins>
<script async src="//server.blockchain-ads.com/www/delivery/asyncjs.php"></script>`;

            } else {
                invocationCode = `
                    <!-- Revive Adserver Asynchronous JS Tag - Generated with Revive Adserver v5.1.0 -->
                    <ins data-revive-zoneid="${zone}" data-revive-id="a55728a252ddb9304b3fd15712250317"></ins>
                    <script async src="//server.blockchain-ads.com/www/delivery/asyncjs.php"></script>`;

            }

            setInvocation1(invocationCode);
            setZonename1(name);
        } catch (error) {
            console.error('Fetch error:', error);
            if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                await updateTokens();

            } else {
                console.error('An unexpected error occurred:', error);
            }
        }
    };




    const fetchZoneData = async (type) => {
        const user = localStorage.getItem("user");
        const parsedUser = JSON.parse(user);


        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-all.php?access_token=` + parsedUser.access_token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "affiliateid": publisher_id
                }),
            });


            const result = await response.json();
            fetchInvocationData1(result.result[0].zoneid, result.result[0].zonename, type);

        } catch (error) {
            console.error('Fetch error:', error);
            if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                await updateTokens();
            } else {
                console.error('An error occurred:', error);
            }
        }
    };

    const publisher_id = JSON.parse(localStorage.getItem("created_publisher_id"));

    if (publisher_id !== null) {
        fetchZoneData();
    }

    const { addCommas, formatLargeNumber } = useFormatter();

    const { campaignTabs, setCampaignTabs } = campaignsState;

    const [state, setState] = useState({
        category: [],
        web3Audience: [],
        chainTargeting: [],
        walletTargeting: [],
        deviceType: [],
        operativeSystem: [],
        browser: [],
        countriesTargeting: [],
    });

    const [showInstallPixelModal, setShowInstallPixelModal] = useState("none");

    return (
        <div className="targeting">
            <div className="wrapper tab-content-center">
                <div className="install-pixel-tab">
                    <h4 className="install-pixel-tab-title ">Install Codes</h4>

                    <p className="install-pixel-tab-description text-style-1">
                        Install the Blockchain-Ads codes on all pages of the website where you want ads to appear.
                    </p>
                    <br />
                    <form action="" onSubmit={handleOnSubmit}>
                        <Select
                            items={items}
                            setItems={setItems}
                            placeholder="Select Invocation Type"
                            isLoading={false}
                            variant="outlined"
                            color="light"
                            className="organization-select"
                            callback={(items) => handleSelectChange(items, "zone")}
                        />
                    </form>
                    <PixelContent invocation1={invocation1} invocation2={invocation2} zonename1={zonename1} zonename2={zonename2} />
                </div>
                <br />
                <br />
                
                <div className="row row-bottom btn-group">
                    <Button
                        size="sm"
                        startIcon={arrowLeft}
                        name="Back"
                        variant="text"
                        color="dark"
                        onClick={() => setActiveTab(0)}
                    />

                    <Modal
                        Child={InstallPixelModal}
                        className="install-pixel-modal tracking-modal modal-dialog-centered"
                        display={showInstallPixelModal}
                        setDisplay={setShowInstallPixelModal}
                        restProps={{ setActiveTab }}
                        Toggler={() => (
                            <Button
                                size="sm"
                                name="Save & Continue"
                                variant="contained"
                                color={isDarkModeEnabled ? "light" : "dark"}
                                onClick={() => {
                                    setShowInstallPixelModal("block");
                                    setCampaignState((prev) => {
                                        return {
                                            ...prev,
                                            campaignInfo: {
                                                ...prev.campaignInfo,
                                                ...state,
                                            },
                                        };
                                    });
                                }}
                            />
                        )}
                    ></Modal>
                </div>
            </div>
        </div>
    );
}

const PixelContent = ({ invocation1, invocation2, zonename1, zonename2 }) => {
    const {
        CreatePixelTagRequest,
        CreateConversionPixelRequest,
        conversionOptions,
        setConversionOptions,
        requestedData: {
            userProfileData: { currentOrganization },
        },
    } = useGlobalContext();




    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={pixel} alt="" />
                    Invocation Codes
                </div>
                <CopyTextButton
                    text={invocation1}
                    size="medium"
                />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {zonename1}
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {invocation1}
                    </SyntaxHighlighter>
                )}
            </div>
            {/* <br></br>

            <div className="row">
                <div className="label">
                    <img src={pixel} alt="" />
                    Invocation Codes
                </div>
                <CopyTextButton
                    text={invocation1}
                    size="medium"
                />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {zonename2}
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {invocation2}
                    </SyntaxHighlighter>
                )}
            </div> */}
        </div>
    );
};

const InstallPixelModal = ({ setIsOpen, setActiveTab }) => {
    const { conversionOptions, campaignsState } = useGlobalContext();
    const { campaignTabs, setCampaignTabs } = campaignsState;
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };
    const { errorAlert, successAlert, getLoadCampaignData, campaignState, creatives } =
        useGlobalContext();

    const handleFinishSetup = async () => {
        successAlert("You have successfully added a website! Please copy the invocation codes below");
    };

    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">Confirmation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                <TrackingHelp />
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="cancel-btn"
                        name="Cancel"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                    <Button
                        className="next-step-btn"
                        name="Continue"
                        size="sm"
                        variant="contained"
                        color="pale-dark"
                        onClick={() => {
                            closeModal();
                            handleFinishSetup();
                            // setTimeout(() => {
                            //setActiveTab((prevNumber) => prevNumber + 1);
                            // setCampaignTabs({
                            //     ...campaignTabs,
                            //     clickable: [...campaignTabs.clickable, 2],
                            //     marked: [...campaignTabs.marked, 2],
                            // });
                            // }, 500);
                        }}
                    />
                </div>
            </div>
        </>
    );
};
