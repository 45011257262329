import React, { useState, useEffect, useRef } from "react";
import {
    SignIn,
    SignUp,
    ForgotPassword,
    Campaigns,
    Campaign,
    Profile,
    Error,
    Support,
    PrivacyPolicy,
    Invitation,
    Referral,
    Tracking,
    Zone,
} from "./pages/index.js";
import { Header, Sidebar, Button, Loading } from "./components/index.js";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useGlobalContext } from "./context.js";
import localData from "./localData.js";
import { Tooltip } from "react-tooltip";

const SESSION_TIMEOUT = 12 * 60 * 60 * 1000; // 12 hours
const CHECK_INTERVAL = 1 * 60 * 60 * 1000; // 1 hour

export default function App() {
    const location = useLocation();
    const {
        initRequests,
        navbarRef,
        marketplaceScrollItems,
        marketplaceTheadRef,
        successAlert,
        warningAlert,
        campaignState,
        setCampaignState,
        isDarkModeEnabled,
        requestedData,
        isLoading,
        isGoogleSignUpLoading,
        handleSignOut,
        isLoggedIn, 
        setIsLoggedIn,
        setAuthUser,
        setUserData,
        _getPublisherWallet
    } = useGlobalContext();
    const { arrowRight } = localData.svgs;

    const mainContentRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            mainContentRef?.current?.scrollTo({
                top: 0,
                behavior: "auto",
            });
        }, 500);
    }, [location.pathname, requestedData.userProfileData]);

    const navigate = useNavigate();
    const [isAuthUserLoading, setIsAuthUserLoading] = useState(true);

    useEffect(() => {
        const checkSessionTimeout = () => {
            const lastActivityTime = parseInt(localStorage.getItem("lastActivityTime")) || 0;
            const inactivityDuration = Date.now() - lastActivityTime;
 
            if (inactivityDuration > SESSION_TIMEOUT) {
                successAlert("Session timeout");
                handleSignOut();
            }
        };
 
        const updateActivityTime = () => {
            localStorage.setItem("lastActivityTime", Date.now());
        };
 
        const user = localStorage.getItem("user");
        setIsLoggedIn(!!user);
        setIsAuthUserLoading(false);

        if (!user) {
            if ( 
                location.pathname !== "/sign-in" &&
                location.pathname !== "/sign-up" &&
                location.pathname !== "/forgot-password" &&
                location.pathname !== "/privacy-policy" &&
                location.pathname !== "/invitation"
            ) {
                navigate("/sign-in");
            }
        } else if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {
            navigate("/");
        }

        if (user) {
            document.addEventListener("mousemove", updateActivityTime);
            document.addEventListener("keydown", updateActivityTime);

            const intervalId = setInterval(checkSessionTimeout, CHECK_INTERVAL);

            return () => {
                clearInterval(intervalId);
                document.removeEventListener("mousemove", updateActivityTime);
                document.removeEventListener("keydown", updateActivityTime);
            };
        }
    }, [location, navigate]);

    useEffect(() => {
        if (!mainContentRef.current || !navbarRef.current) return;
        const shrink = () => {
            navbarRef.current.classList.toggle("shrink", mainContentRef.current.scrollTop > 0);
        };

        const mainContentElement = mainContentRef.current;

        mainContentElement.addEventListener("scroll", shrink);

        return () => {
            mainContentElement.removeEventListener("scroll", shrink);
        };
    }, [mainContentRef.current, navbarRef.current]);

    useEffect(() => {
        const marketplace = ["/chat", "/additional-products/marketplace"];
        if (marketplace.includes(location.pathname)) {
            document.body.classList.add("marketplace-mode");
        } else {
            document.body.classList.remove("marketplace-mode");
        }
    }, [location]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) return;
        setUserData((prev) => ({ ...prev, data: user }));
        // _getStats();
        _getPublisherWallet()
    }, [location]);


    if (isAuthUserLoading) return <Loading className="extended" />;
    return (
        <>
            {isLoggedIn ? (
                <>
                    {!location.pathname.includes("/invitation") &&
                    !location.pathname.includes("/privacy-policy") ? (
                        <>
                            <Sidebar />

                            <div
                                className={`main-content ${isDarkModeEnabled ? "dark-mode" : ""}`}
                                ref={mainContentRef}
                            >
                                <AnimatePresence mode="wait">
                                    <Routes location={location} key={location.pathname}>
                                        <Route path="/" element={<Campaigns />} />
                                        <Route path="/statistics" element={<Campaigns />} />
                                        <Route path="/statistics/:id" element={<Campaign />} />
                                        <Route path="/profile" element={<Profile />} />
                                        <Route path="/support" element={<Support />} />
                                        <Route path="/referral" element={<Referral />} />
                                        <Route path="/my-websites" element={<Tracking />} />
                                        <Route path="/my-zones" element={<Zone />} />
                                        <Route path="*" element={<Error />} />
                                    </Routes>
                                </AnimatePresence>
                            </div>
                        </>
                    ) : (
                        <Routes location={location} key={location.pathname}>
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/invitation" element={<Invitation />} />
                        </Routes>
                    )}
                </>
            ) : (
                <AnimatePresence mode="wait">
                    <Routes location={location} key={location.pathname}>
                        <Route path="/sign-in" element={<SignIn />} />
                        <Route path="/sign-up" element={<SignUp />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/invitation" element={<Invitation />} />
                        <Route path="*" element={<Error />} />
                    </Routes>
                </AnimatePresence>
            )}
            <Tooltip id="tooltip-table-name" className="custom-tooltip" />
            <Tooltip id="tooltip-table-settings" className="custom-tooltip" />
            
           
           
            <Tooltip id="tooltip-table-ctr" className="custom-tooltip" />
            <Tooltip id="tooltip-table-ad-spend" className="custom-tooltip" />
            <Tooltip id="tooltip-table-cpc" className="custom-tooltip" />
            <Tooltip id="tooltip-table-details" className="custom-tooltip" />
        </>
    );
}
