import React, { useState, useEffect } from "react";
import { Drawer, Button, Tabs } from "../../../components";
import localData from "../../../localData";
import InstallPixelTab from "./tracking-tabs/install-pixel-tab/InstallPixelTab";
import CreateConversionTab from "./tracking-tabs/create-conversion-tab/CreateConversionTab";
import { useGlobalContext } from "../../../context";

const { arrowLeft, plus } = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function TrackingDrawer() {
    const { requestedData, trackingState } = useGlobalContext();

    const { displayInstallPixel, setDisplayInstallPixel, setIsEditingMode, setDefaultTab } = trackingState;

    return (
        <Drawer
            display={displayInstallPixel}
            setDisplay={setDisplayInstallPixel}
            disabled={1}            
            className="drawer-right tracking-drawer"
            callback={() => {}}
            Component={DrawerComponent}
        />
    );
}

const DrawerComponent = ({ callbackFromParent }) => {
    const { requestedData, trackingState } = useGlobalContext();

    const { defaultTab, setDefaultTab, isEditingMode } = trackingState;
    return ( 
        <div className={`${isEditingMode ? "editing-mode" : ""}`}>
            <h2 className="display-2">Generate Invocation Codes</h2>
            <p className="text-style-1">
                Set up invocation code with Blockchain-Ads to have ads display on your website
                website.
            </p>
            {/* <div>step 1 ----------- step 2 ---------- step 3</div> */}

            <br />
            <Tabs
                defaultTab={defaultTab}
                tabsProps={{ callbackFromParent }}
                tabs={trackingTabs}
                title="Setup Tracking"
                // indicatorIcon={question}
                className="Generate Invocation Code"
            />
        </div>
    );
};

const ConfirmationTab = ({ setActiveTab }) => {
    return (
        <div>
            confirmation tab
            <br />
            <br />
            <br />
            <Button
                name="back"
                color="pale-dark"
                startIcon={arrowLeft}
                size="sm"
                onClick={() => setActiveTab(1)}
            />
        </div>
    );
};

const trackingTabs = [
    
    {
        title: "Generate Invocation Code",
        content: <CreateConversionTab />,
        id: "create-conversion-tab-jfldsjfl23",
    },
   
];
