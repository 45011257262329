import React, { useEffect, useState } from "react";
import { Dropdown, Select, Autocomplete, Button, Field, NestedModal, Checkbox } from "..";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../../hooks/useFetch";
import { auth } from "../../config/firebase.js";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter.jsx";



export default function DepositComponent({ setIsOpen }) {
    const {
        errorAlert,
        requestedData,
        // requestedData: {
        //     userProfileData: {
        //         currentOrganization: { balance, PredictedBalance },
        //     },
        // },
        formatNumber,
        isDarkModeEnabled,
        balance,
        userData
    } = useGlobalContext();

    const {addCommas} = useFormatter()

    const { preloaderBig, pay, plants } = localData.images;
    const { close, wallet, budget } = localData.svgs;
    const [isLoading, setIsLoading] = useState();
    const { getAllCurrencies, getPaymentWithInvoice, paymentWithInvoice } = useFetch();

    const [state, setState] = useState({
        priceAmount: 0,
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const [url, setURL] = useState("");
    const [display, setDisplay] = useState("none");

    const NestedModalChildren = () => {
        const [isChecked, setIsChecked] = useState(false);
        const { isDarkModeEnabled } = useGlobalContext();

        const handleCheckbox = (target) => {
            setIsChecked(target);
        };

        return (
            <div className="redirect-modal">
                <div className="nested-modal-header">
                    <h5 className="nested-modal-title" id="exampleModalLabel">
                        Payment Invoice
                    </h5>
                </div>
                <div className="nested-modal-body">
                    <p className="text info-text">You are about to be redirected</p>

                    <p className="text">
                        Important points to note before depositing funds for your campaign:
                    </p>
                    <br />

                    <ul>
                        <li>
                            <span className="list-style list-style-primary"></span>
                            <p>Blockchain-Ads and/or the publishers may reject any ads at any moment.</p>
                        </li>
                        <li>
                            <span className="list-style list-style-primary"></span>
                            <p>
                                Any refunds are at the entire discretion of Blockchain-Ads with a minimum 20%
                                fee to be retained.
                            </p>
                        </li>
                    </ul>

                    <Checkbox
                        callback={handleCheckbox}
                        checked={isChecked}
                        color="primary"
                        label="I understand and I want to proceed"
                        size="sm"
                    />
                </div>
                <div className="nested-modal-footer">
                    <Button variant="contained" color="primary" size="sm" disabled={!isChecked}>
                        <a href={url} target="_blank">
                            Submit
                        </a>
                    </Button>
                </div>
            </div>
        );
    };

    const handleSubmit = async () => {
        // const depositRaw = {
        //     price_amount: parseInt(state.priceAmount),
        //     uuid: auth.currentUser.uid,
        // };
        // setIsLoading(true);
        // try {
        //     const data = await paymentWithInvoice(() => {}, depositRaw);
        //     console.log(data, " =paymentWithInvoice= request");
        //     // window.location.href = data.invoice_url
        //     // window.open(data.invoice_url, "_blank");
        //     setURL(data.invoice_url);
        //     setDisplay("block");
        // } catch (err) {
        //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        //     console.log(err, "=paymentWithInvoice= request error");
        // }
        // setIsLoading(false);
        alert("withdraw submitted");
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const [isBalanceOpen, setIsBalanceOpen] = useState(false);

    return (
        <div className="deposit-component">
            <div className="modal-header">
                <h2 className="modal-title">Withdraw your payout</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>

            <div className="modal-body">
                {/* <img className="modal-image" src={pay} alt="" /> */}
                <Dropdown
                    {...{
                        title: "Balance",
                        variant: "contained",
                        color: "light",
                        className: "balance-dropdown",
                        startIcon: wallet,
                        isOpen: isBalanceOpen,
                        setIsOpen: setIsBalanceOpen,
                    }}
                >
                    <div className="balance-amount">
                    $
                            {parseFloat(balance.data.total_revenue) > 0
                                ? formatNumber(parseFloat(balance.data.total_revenue)?.toFixed(0) || "0")
                                : 0}
                    </div>
                    <p className="balance-text">Available Balance</p>
                </Dropdown>

                <p className="balance-info">Choose your preferred currency for your payout.</p>
                <img className="modal-cover" src={plants} alt="" />

                <Field
                    type="number"
                    label="Amount"
                    color="secondary"
                    placeholder=""
                    value={state.priceAmount}
                    required={true}
                    name="priceAmount"
                    size="sm"
                    callback={handleOnChange}
                    startIcon={budget}
                />
                <div
                    data-tooltip-id="deposit-btn"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        "Please deposit a minimum amount of 10000."
                    )}
                >
                    <Button
                        variant="contained"
                        name="Submit"
                        color={`${isDarkModeEnabled ? "light" : "dark"}`}
                        onClick={handleSubmit}
                        icon={
                            isLoading ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={isLoading || state.priceAmount < 0}
                        className="balance-btn"
                        // tooltipContent={state.priceAmount < 10000 ? "Please deposit a minimum amount of 10000.": ''}
                    />
                    {/* {state.priceAmount < 0 && <Tooltip id="deposit-btn" className="custom-tooltip" />} */}
                </div>
                {/* <Autocomplete
                    {...{
                        items: items,
                        setItems: setItems,
                        placeholder: "loading...",
                        variant: "outlined",
                        color: "secondary",
                        label: "Currency:",
                        className: "modal-autocomplete",
                    }}
                /> */}
            </div>
            <NestedModal
                togglerClassName="nested-modal-toggler"
                className="modal-dialog-centered redirect-modal"
                display={display}
                setDisplay={setDisplay}
            >
                <NestedModalChildren />
            </NestedModal>
            <div className="modal-footer"></div>
        </div>
    );
}
