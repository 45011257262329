import axios from 'axios';


const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const getAuthToken = () => {
  const user = localStorage.getItem("user");
  const parsedUser = JSON.parse(user);
  return parsedUser.access_token;
};

export const postCall = async (url, formData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',  // Set the token in the Authorization header
      }
    };
    const response = await apiService.post(url, formData, config);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const getAPIwithAuth = async (url, formData) => {
  try {
    const authToken = getAuthToken();
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await apiService.get(`${url}?access_token=${authToken}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postAPIwithAuth = async (url, formData) => {
  try {
    const authToken = getAuthToken();
    console.log(authToken)
    const config = {
      headers: {
      }
    };
    const response = await apiService.post(`${url}?access_token=${authToken}`, formData, config);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const putAPIwithAuth = async (url, formData) => {
  try {
    const authToken = getAuthToken();
    const config = {
      headers: {
        Authorization: `${authToken}` // Set the token in the Authorization header
      }
    };
    const response = await apiService.put(url, formData, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteAPIwithAuth = async (url, formData) => {
  try {
    const authToken = getAuthToken();
    const config = {
      headers: {
        Authorization: `${authToken}` // Set the token in the Authorization header
      }
    };
    const response = await apiService.delete(url, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

